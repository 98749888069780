html,
body {
  padding: 0;
  min-height: 100vh;
  background-color: #f9fafb;
  font-family: "Inter", sans-serif;
}

#root {
  /* min-height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: space-between; */
}

:root {
  --app-bg-color: #e98100;
  --app-sub-bg-color: #008934;
  --color: #f79009;
  --sub-color: #008934;
  --gray-color: #667085;
  --bg-color: #f9fafb;
  --gray: #667085;
  --border: 1px solid #e0e0e0;
  --yellow-bg-color: #e98100;
  --gray-border-color: #e0e0e0;
  --gray-text-color: #828282;
  --gray-bg-color: #f4f4f4;
  --footer-color: #667085;
  --text-error-color: #d32f2f;
  --blue-text-color: #020aba;
  --green-text-color: #008934;
  --register-green-btn-bg: linear-gradient(
    270deg,
    #008934 18.83%,
    #2db422 100%
  );
}

.MuiTypography-root.app_title {
  font-size: 20px;
  font-weight: 700;
}

a.see_all_text p {
  color: #1d2939;
  cursor: pointer;
}

.row-alcenter {
  display: flex;
  align-items: center;
}

/* scroll configs */
::-webkit-scrollbar {
  width: 8px;
}

.MuiTypography-root.input-label {
  color: #344054;
  text-align: left;
}

.MuiTypography-root.input-label.required::after {
  content: "*";
  color: #f04438;
  margin-left: 2px;
}

.MuiTypography-root.bold {
  font-weight: 700;
}

.border {
  border: 1px solid #eaecf0;
}

a.MuiTypography-root {
  color: #000;
  text-decoration-color: #000;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

input[type="number"] {
  -moz-appearance: textfield;
}

.sponsored-label {
  width: fit-content;
  padding: 4px 8px;
  background-color: #fff;
  border-radius: 8px;
  display: flex;
  gap: 4px;
  align-items: center;
}

/* Mobile view */
@media only screen and (max-width: 600px) {
  ::-webkit-scrollbar {
    width: 8px;
    height: 8px;
  }
  ::-webkit-scrollbar-track {
    background: #f1f1f1;
  }
  ::-webkit-scrollbar-thumb {
    background: #bababa;
    border-radius: 8px;
  }
  ::-webkit-scrollbar-thumb:hover {
    background: #888;
  }
  .MuiTypography-root.input-label {
    font-size: 12px;
  }
}
